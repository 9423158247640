import axios from 'axios';

const apiURL = process.env.GATSBY_API_URL;

export const getLocation = (locationId, callback) => {
    get(`/wp-json/wp/v2/location/${locationId}`, callback);
};

export const getDirections = (origin, destination, callback) => {
    get(`/wp-json/brew/v1/directions/?origin=${origin}&destination=${destination}`, callback);
};

export const sendItineraryEmail = (data, emailTo, locale, callback) => {
    let requestData = {
        'tripData': data,
        'emailTo': emailTo,
        'lang': locale
    };
    asyncStringify(requestData).then(data => {
        post(`/wp-json/brew/v1/trip-email`, { trip: data }, callback);
    });
};

export const post = (path, data, callback ) => {
    axios
        .post(`${apiURL}${path}`, data)
        .then(response => {
            callback(false, response);
        })
        .catch(error => {
            // Handle error.
            callback(true, false);
        });
};

const get = (path, callback ) => {
    axios
        .get(`${apiURL}${path}`)
        .then(response => {
            callback(false, response);
        })
        .catch(error => {
            // Handle error.
            callback(true, false);
        });
};

const asyncStringify = (str) => {
    return new Promise((resolve, reject) => {
        resolve(JSON.stringify(str));
    });
}
