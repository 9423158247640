import language from "../../data/language.yaml";

export function shuffle(array) {
    for(let i = array.length - 1; i > 0; i--){
        const j = Math.floor(Math.random() * i)
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
    }
    return array;
}

export function metresToMiles(metres, locale){
    return `${Math.ceil(metres * 0.000621371192)} ${language.myTrip.miles[locale]}`;
}

export function minutesToTime(mins, locale){
    let hours = Math.floor(mins / 60);
    let minutes = Math.floor(mins % 60);

    if(hours > 0) {
        return `${hours} ${language.member.averageDuration.hours[locale]}${hours > 1 ? 's' : ''} ${minutes} ${language.member.averageDuration.minutes[locale]}${minutes > 1 ? 's' : ''}`
    } else {
        return `${minutes} ${language.member.averageDuration.minutes[locale]}${minutes > 1 ? 's' : ''}`
    }
}
