/**
 * GTM tracking functions
 * @param url
 */

export const trackBookingClick = (url) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'book-online-click',
        customAction: 'click',
        customValue: url
    });
};

export const trackWebsiteClick = (url) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'view-website-click',
        customAction: 'click',
        customValue: url
    });
};

export const trackViewMember = (url) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'view-member-click',
        customAction: 'click',
        customValue: url
    });
};

export const trackLoadItinerary = () => {
    trackEvent({
        event: 'custom-actions',
        customType: 'load-itinerary',
        customAction: 'click',
        customValue: ''
    });
};

export const trackAddToTrip = (url) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'add-to-trip-click',
        customAction: 'click',
        customValue: url
    });
};

export const trackSnowdoniaPassPurchase = (quantity) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'pass-purchase',
        customAction: 'click',
        customValue: quantity
    });
};

export const trackMapPurchase = (quantity) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'map-purchase',
        customAction: 'click',
        customValue: quantity
    });
};

export const trackSnowdoniaPassRegister = () => {
    trackEvent({
        event: 'custom-actions',
        customType: 'pass-register'
    });
};

const trackEvent = (event) => {
    if(typeof window !== `undefined`) {
        let data = window.dataLayer;
        if(data) data.push(event);
    }
};
