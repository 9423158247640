import React from 'react';
import {Button} from "antd";
import PropTypes from 'prop-types';
import Link from "../../components/Link";
import {trackViewMember} from "../../services/gtm";

class ItineraryItem extends React.Component {

    constructor(props){
        super(props);
    }

    static propTypes = {
        color: PropTypes.string,
        name: PropTypes.string,
        image: PropTypes.string,
        duration: PropTypes.string,
        canRemove: PropTypes.bool,
        hasNext: PropTypes.bool,
        hasPrevious: PropTypes.bool,
        distance: PropTypes.any,
        // onRemove
        // onDuplicate
        // onMoveUp
        // onMoveDown
    };

    render() {
        return (
            <React.Fragment>
                    <li className="between-time">
                        <span>{this.props.distance ? `Drive time: ${this.props.distance}` : ''}</span>
                    </li>


                <li className="itinerary-item" data-color={this.props.color}>
                    <span className="drag-handle">
                         <>
                            <Button className="move-up" onClick={this.props.onMoveUp} disabled={!this.props.hasPrevious}>
                                Move Up
                            </Button>
                            <Button className="move-down" onClick={this.props.onMoveDown} disabled={!this.props.hasNext}>
                                Move Down
                            </Button>
                        </>
                    </span>

                    <span className="left">
                        <span className="location-image" style={{backgroundImage: `url('${this.props.image}')`}}/>
                    </span>
                    <span className="right">
                        <span className="location-name"><Link to={this.props.link} onClick={() => trackViewMember(this.props.link)}>{this.props.name}</Link></span>
                        <span className="duration">{this.props.duration}</span>
                    </span>

                    <span className="actions">
                        {this.props.canRemove ?
                            <>
                                <Button className="remove-item" onClick={this.props.onRemove}>
                                    Remove
                                </Button>
                                <Button className="duplicate-item" onClick={this.props.onDuplicate}>
                                    Duplicate
                                </Button>
                            </>
                        : ''}
                    </span>

                </li>
            </React.Fragment>
        );
    }
}

export default ItineraryItem
